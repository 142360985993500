import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CheckVoucher } from './voucher';
import { Common } from '../common';


@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent {

  constructor(private http: HttpClient) { }

  showLoader = false;
  showButton = false;
  key: string;
  status: string;
  voucher: CheckVoucher;
  voucherURL: string;
  private url: string = Common.getUrl();

  checkVoucher(value: string) {
    this.showLoader = false;
    this.showButton = false;
    this.status = '';
    if (value.length >= 5) {
      this.showButton = false;
      this.showLoader = true;
      this.key = '';
      this.getVoucher(value).subscribe(res => {
        if (res.isValid === true) {
          this.showButton = true;
          this.status = 'success';
          this.key = value;
          this.voucherURL = value.replace(/\//gi, '-');
        } else {
          this.status = 'wrong';
        }
        this.showLoader = false;
      });
    }
  }
  getVoucher(key: string): Observable<CheckVoucher> {
    return this.http.get<CheckVoucher>(this.url + 'voucher/checkvoucher?key=' + key);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CheckVoucher } from '../voucher/voucher';
import { ResevationTime, VoucherDetails, SaveVoucher } from './reservation-calendar';
import { Common } from '../common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reservation-calendar',
  templateUrl: './reservation-calendar.component.html',
  styleUrls: ['./reservation-calendar.component.css', './reservation-form.component.css', './picker.min.css']
})
export class ReservationCalendarComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router, private http: HttpClient, public datepipe: DatePipe) {
  }
  private reservationTimeList: ResevationTime[] = [];
  private param: string;
  private saveVoucher: SaveVoucher = new SaveVoucher();
  private vocherDetails: VoucherDetails = new VoucherDetails();
  private url: string = Common.getUrl();
  private flightTimeMultiplicactionHelper: number;
  /////////////////////////////// CALENDAR //////////////////////////////
  public min: Date;
  public max: Date;
  public date: Date;
  /////////////////////////////// CALENDAR //////////////////////////////
  ///////////////////////////// HTML HELPERS ////////////////////////////
  private showLoader: boolean;
  private errorClass = '';
  private errorList = [];
  private choosedTime = '';
  private timeList: string[] = [];
  private reservationError = '';
  ///////////////////////////// HTML HELPERS ////////////////////////////

  ngOnInit() {
    this.param = this.route.snapshot.paramMap.get('gid');
    this.checkGID(this.param).subscribe(res => {
      if (res.isValid === false) {
        this.router.navigate(['/voucher']);
      } else {
        this.getVoucherDetails(this.param).subscribe(y => {
          this.vocherDetails = y;
          this.date = y.date;
          this.saveVoucher.email = y.email;
          this.saveVoucher.name = y.name;
          this.saveVoucher.phoneNumber = y.phoneNumber;
          this.max = y.expirationDate;
          this.min = new Date();
          this.getReservedHours(this.datepipe.transform(y.date, 'dd/MM/yyyy'), this.param).subscribe(x => {
            this.timeList = x;
            this.generateTimeList();
          });
        });
      }
    });

  }

  updateResevationTime() {
    this.getReservedHours(this.datepipe.transform(this.date, 'dd/MM/yyyy'), this.param).subscribe(x => {
      this.timeList = x;
      this.reservationTimeList = [];
      this.generateTimeList();
    });
  }

  clickOnTimeButton(Id: number, choosedTime: string) {
    this.choosedTime = choosedTime;
    this.timeActionCommon(Id, true);
  }
  eventOnTimeButton(Id: number) {
    this.timeActionCommon(Id, false);
  }
  leaveOnTimeButton() {
    this.reservationTimeList.forEach(x => {
      x.helperClass = '';
    });
  }

  save() {
    this.showLoader = true;
    this.reservationError = '';
    if (this.validator(this.saveVoucher) === false) {
      this.showLoader = false;
      return;
    }
    this.saveVoucher.key = this.param;
    this.saveVoucher.promotionId = this.vocherDetails.promotionId;
    this.saveVoucher.id = this.vocherDetails.id;
    this.saveVoucher.flightTimeMultiplication = this.flightTimeMultiplicactionHelper;
    this.saveVoucher.startTime = this.choosedTime;
    this.saveVoucher.date = this.datepipe.transform(this.date, 'dd/MM/yyyy');
    this.saveVoucherDet(this.saveVoucher).subscribe(x => {
      this.updateResevationTime();
      this.showLoader = false;
      if (x == false) {
        console.log(x);
        this.reservationError = 'Przykro nam ale ktoś już zarezerwował lot w tym samym czasie. Wybierz ponownie godzinę lotu';
      }
      else {
        this.router.navigate(['/done/' + this.param]);
      }
    });
  }



  validator(obj) {
    this.errorClass = '';
    this.errorList = [];
    let isOk = true;
    if (obj.name === '' || obj.name == null) {
      this.errorClass = 'errorMessage';
      isOk = false;
      this.errorList.push('Podaj imię i nazwisko!');
    } if (obj.phoneNumber === '' || obj.phoneNumber == null) {
      isOk = false;
      this.errorClass = 'errorMessage';
      this.errorList.push('Podaj poprawny numer telefonu');
    } if (obj.email === '' || obj.email == null) {
      isOk = false;
      this.errorClass = 'errorMessage';
      this.errorList.push('Podaj poprawny adres email');
    } if (this.choosedTime === '') {
      isOk = false;
      this.errorClass = 'errorMessage';
      this.errorList.push('Wybierz godzinę rezerwacji');
    }
    if (!obj.agreement) {
      isOk = false;
      this.errorClass = 'errorMessage';
      this.errorList.push('Musisz zaakceptować regulamin');
    }

    if (Common.emailValidator(obj.email)) {
      isOk = false;
      this.errorClass = 'errorMessage';
      this.errorList.push('Podano błędny adres email');
    }
    if (Common.phoneNumberValidator(obj.phoneNumber)) {
      isOk = false;
      this.errorClass = 'errorMessage';
      this.errorList.push('Podano błędny numer telefonu');
    }
    return isOk;
  }


  saveVoucherDet(saveVoucher: SaveVoucher) {
    return this.http.post(this.url + 'voucher/SaveReservation', saveVoucher);

  }
  getVoucherDetails(key: string): Observable<VoucherDetails> {
    return this.http.get<VoucherDetails>(this.url + 'voucher/GetVoucherDetails?key=' + key);
  }

  checkGID(key: string): Observable<CheckVoucher> {
    return this.http.get<CheckVoucher>(this.url + 'voucher/checkvoucher?key=' + key);
  }
  getReservedHours(date: string, key: string): Observable<string[]> {
    if (date == null) {
      return this.http.get<string[]>(this.url + 'voucher/getReservedHours?key=' + key);
    }
    return this.http.get<string[]>(this.url + 'voucher/getReservedHours?date=' + date + "&key=" + key);
  }

  generateTimeList() {
    let helperHour = 9;
    let helperMinutes = 0;
    let index = 1;
    while (helperHour !== 21) {
      if (helperMinutes === 60) {
        helperMinutes = 0;
        helperHour++;
      }
      if (helperHour === 21) {

        continue;
      }
      const model = new ResevationTime();
      model.id = index;
      if (helperHour < 10) {
        model.value = "0" + helperHour + ':' + helperMinutes;
      }
      else {
        model.value = helperHour + ':' + helperMinutes;
      }
      if (helperMinutes === 0) { model.value += '0'; }
      if (this.timeList.indexOf(model.value.toString()) !== -1) {
        model.class = 'closeDisable';
      }
      this.reservationTimeList.push(model);
      index++;
      helperMinutes += 10;
    }

  }
  timeActionCommon(Id: number, OnClick: boolean) {
    let multiplication = this.vocherDetails.flightTimeMultiplication;
    let takeNextIdHelper = false;
    if (OnClick) {
      this.reservationError = '';
      this.flightTimeMultiplicactionHelper = 0;
    }
    this.reservationTimeList.forEach(x => {
      if (OnClick) {
        x.choosedClass = '';
      } else {
        x.helperClass = '';
      }
      if (multiplication !== 0) {
        if (x.id === Id) {
          takeNextIdHelper = true;
        }
        if (x.class === 'closeDisable') {
          takeNextIdHelper = false;
        } else if (takeNextIdHelper) {
          if (OnClick) {
            x.choosedClass = 'timeChoosed';
            this.flightTimeMultiplicactionHelper++;
          } else {
            x.helperClass = 'timeHover';
          }
          multiplication--;
        }
      }
    });
    if (OnClick && this.flightTimeMultiplicactionHelper !== this.vocherDetails.flightTimeMultiplication) {
      const hh = (this.flightTimeMultiplicactionHelper * 10) - 10;
      const ss = (this.vocherDetails.flightTimeMultiplication * 10) - 10;
      this.reservationError = 'Nie możesz zarezerwować ' + hh + ' minut' + ' gdy do wykorzystania masz ' + ss + ' minut';
    }
  }
}

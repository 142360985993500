export class ResevationTime {
    id: number;
    value: string;
    class: string;
    helperClass: string;
    choosedClass: string;
}

export class VoucherDetails {
    id: number;
    key: string;
    value: number;
    expirationDate: Date;
    flightTimeMultiplication: number;
    promotionTitle: string;
    promotionId: number;
    date: Date;
    name: string;
    phoneNumber: string;
    email: string;
}

export class SaveVoucher {
    id: number;
    key: string;
    flightTimeMultiplication: number;
    promotionId: number;
    date: string;
    startTime: string;
    name: string;
    email: string;
    phoneNumber: string;
    clientDescription: string;
    agreement: boolean;
    newsletter: boolean;
}

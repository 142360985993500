export class MainTask {
    title;
    name;
    email;
    content;
}

export class ErrorClass {
    title = '';
    name = '';
    email = '';
    content = '';
}



export class PriceInfo {
    voucherType: number;
    price: number;
    url: string;
    urlWithPostPrice: string;
    imgUrl: string;
}

export class Promotion{
    imgUrl: string;
}
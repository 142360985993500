import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CheckVoucher } from '../voucher/voucher';
import { Common } from '../common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-extend-voucher',
  templateUrl: './extend-voucher.component.html',
  styleUrls: ['../voucher/voucher.component.css']
})
export class ExtendVoucherComponent implements OnInit {

  constructor(private http: HttpClient,private route: ActivatedRoute) { }

  showLoader = false;
  showButton = false;
  buttonValue = "";
  key: string;
  status: string;
  voucher: CheckVoucher;
  voucherURL: string;
  private url: string = Common.getUrl();
  private param: string;
  private extend: number = 1;

  ngOnInit() {
    this.param = this.route.snapshot.paramMap.get('type');
    if(this.param == "zmiana-terminu"){
      this.buttonValue = "Zmień termin rezerwacji"
      this.extend = 0
    }
    else{
      this.buttonValue = "Przedłuż ważność vouchera"
    }

  }
  checkVoucher(value: string) {
    this.showLoader = false;
    this.showButton = false;
    this.status = '';
    if (value.length >= 5) {
      this.showButton = false;
      this.showLoader = true;
      this.key = '';
      this.getVoucher(value).subscribe(res => {
        if (res.isValid === true) {
          this.showButton = true;
          this.status = 'success';
          this.key = value;
          this.voucherURL = value.replace(/\//gi, '-');
        } else {
          this.status = 'wrong';
        }
        this.showLoader = false;
      });
    }
  }

  goToPayment(key){
    let desc = "Voucher numer: " + key + " " + this.param;
    let url = "https://sklep.przelewy24.pl/zakup.php?z24_id_sprzedawcy=217567&z24_kwota=9900&z24_crc=ae42712d&z24_nazwa="+ desc+"&z24_return_url=http://www.lotymarzen.pl/";
    window.location.href = url;
  }
  getVoucher(key: string): Observable<CheckVoucher> {
    return this.http.get<CheckVoucher>(this.url + 'voucher/checkvoucherforpayment?key=' + key +'&extend='+this.extend);
  }

}

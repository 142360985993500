import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common';
import { ReservationDetails } from './reservationDone';

@Component({
  selector: 'app-reservationDone',
  templateUrl: './reservationDone.component.html',
  styleUrls: ['./reservationDone.component.css']
})
export class ReservationDoneComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router, private http: HttpClient) { }
  private param: string;
  private model: ReservationDetails;
  private url: string;
  ngOnInit() {
    this.param = this.route.snapshot.paramMap.get('gid');
    this.url = Common.getUrl();
    this.checkGID(this.param).subscribe(res => {
      if (res.isValid === false) {
        this.router.navigate(['/voucher']);
      }
      this.model = res;
    });
  }


  checkGID(key: string): Observable<ReservationDetails> {
    return this.http.get<ReservationDetails>(this.url + 'voucher/GetReservationDone?key=' + key);
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { VoucherComponent } from './voucher/voucher.component';
import { ExtendVoucherComponent } from './extend-voucher/extend-voucher.component';
import { ReservationCalendarComponent } from './reservationCalendar/reservation-calendar.component';
import { ReservationDoneComponent } from './reservationDone/reservationDone.component';
import { MainComponent } from './main/main.component';
import { ReedemComponent } from './reedem/reedem.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import {SlideshowModule} from 'ng-simple-slideshow'; // https://www.npmjs.com/package/ng-simple-slideshow

import { ScrollToModule } from 'ng2-scroll-to-el';

@NgModule({
    declarations: [
        AppComponent,
        VoucherComponent,
        ReservationCalendarComponent,
        ReservationDoneComponent,
        MainComponent,
        ExtendVoucherComponent,
        ReedemComponent
    ],
    imports: [
        BrowserModule,
        ScrollToModule.forRoot(),
        HttpClientModule,
        FormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        BrowserAnimationsModule,
        SlideshowModule,
        RouterModule.forRoot([
            { path: '', component: MainComponent },
            { path: 'voucher', component: VoucherComponent },
            { path: 'extend/:type', component: ExtendVoucherComponent },
            { path: 'rezerwacja/:gid', component: ReservationCalendarComponent },
            { path: 'done/:gid', component: ReservationDoneComponent },
            { path: 'reedem', component: ReedemComponent },
            { path: '', redirectTo: '', pathMatch: 'full' },
            { path: '**', redirectTo: '', pathMatch: 'full' }
        ])
    ],
    providers: [DatePipe,{provide: OWL_DATE_TIME_LOCALE, useValue: 'pl'}],
    bootstrap: [AppComponent]
})

export class AppModule { }

// https://daniel-projects.firebaseapp.com/owlng/date-time-picker#events-owl-date-time
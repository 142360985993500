import { AfterViewInit, Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Common } from '../common';
import { MainTask, ErrorClass } from './main';
import { HttpClient } from '@angular/common/http';
import {  PriceInfo,Promotion } from './main';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['b.css', 'p.css', './main.component.css']
})
export class MainComponent implements OnInit {

  private url: string = Common.getUrlWithouApi();
  private urlApi: string = Common.getUrl();
  loadAPI: Promise<any>;
  isFound: boolean;
  private mainTask = new MainTask();
  private isOk = true;
  private prices: Array<PriceInfo> = new Array<PriceInfo>();
  private errorClass = new ErrorClass();
  private promotions: Array<Promotion> = new Array<Promotion>();
  private showSuccessAlert = false;
  private showDangerAlert = false;
  private emailNewsletter = null;
  private hideModal = "displaynone";
  private message = '';
  private imageUrlArray = ['../../assets/a1.jpg', '../../assets/a2.jpg', '../../assets/a3.jpg', '../../assets/a4.jpg', '../../assets/a5.jpg'];
  private sendAsGift: boolean = false;

  ngOnInit() {
    this.getPriceInfo().subscribe(x => {      
      this.prices = x;
    });
    this.getPromotion().subscribe(x=> {
      if(x.length != 0){
        this.hideModal = ''
      }
      this.promotions = x;
    });

  }

  constructor(private http: HttpClient) {
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }

  public saveNewsletter() {
    if (this.emailNewsletter == null || this.emailNewsletter === '' || Common.emailValidator(this.emailNewsletter)) {
      this.showAlert('Błędny adres email');
    }
    this.saveNewsletterPost(this.emailNewsletter).subscribe(res => {
      if (res == false) {
        this.showAlert('Taki email istnieje już w bazie');
      } else {
        this.showSuccess('Dodaliśmy Twój adres email do bazy!');
        this.emailNewsletter = null;
      }
    });
  }

  public saveAsk() {
    this.errorClass = new ErrorClass();
    this.isOk = true;
    if (Common.emailValidator(this.mainTask.email) || this.mainTask.content.length > 100) {
      this.isOk = false;
      this.errorClass.email = 'error';
      this.showAlert('Błędne dane');
    }
    if (this.mainTask.name === '' || this.mainTask.name == null || this.mainTask.content.length > 100) {
      this.isOk = false;
      this.errorClass.name = 'error';
      this.showAlert('Błędne dane');
    }
    if (this.mainTask.title === '' || this.mainTask.title == null || this.mainTask.content.length > 99) {
      this.isOk = false;
      this.errorClass.title = 'error';
      this.showAlert('Błędne dane');
    }
    if (this.mainTask.content === '' || this.mainTask.content == null || this.mainTask.content.length > 500) {
      this.isOk = false;
      this.errorClass.content = 'error';
      this.showAlert('Błędne dane');
    }
    if (this.isOk) {
      this.saveContact(this.mainTask).subscribe(x => {
        this.showSuccess('Dodaliśmy Twoje pytanie do bazy. Skontaktujemy się z Tobą najszybciej jak to możliwe');
        this.mainTask = new MainTask();
      });

    }
  }
  private showSuccess(m: string) {
    this.message = m;
    this.showDangerAlert = false;
    this.showSuccessAlert = true;
    setTimeout(() => { this.showSuccessAlert = false; }, 4000);
  }

  private showAlert(m: string) {
    this.message = m;
    this.showSuccessAlert = false;
    this.showDangerAlert = true;
    setTimeout(() => { this.showDangerAlert = false; }, 4000);
  }
  public loadScript() {
    this.isFound = false;
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('loader')) {
        this.isFound = true;
      }
    }

    if (!this.isFound) {
      const dynamicScripts = [this.url + 'js/map.js'];

      for (let i = 0; i < dynamicScripts.length; i++) {
        const node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }

  private GoToPayment(voucherNymber: number, viaPost: boolean){
    if(viaPost){
      window.open(this.prices[voucherNymber].urlWithPostPrice, '_blank');
    }
    else{
      window.open(this.prices[voucherNymber].url, '_blank');
    }
  }

  private hidePromotion(){
    this.hideModal = 'displaynone'
  }

  saveContact(mainTask: MainTask) {
    return this.http.post(this.urlApi + 'contact/Save', mainTask);
  }

  saveNewsletterPost(mail: string) {
    return this.http.post(this.urlApi + 'newsletter/AddNewEmail/' + mail, null);
  }

  getPriceInfo(): Observable<PriceInfo[]> {
    return this.http.get<PriceInfo[]>(this.urlApi + 'Price/GetPricesApi');
  }

  getPromotion(): Observable<Promotion[]> {
    return this.http.get<Promotion[]>(this.urlApi + 'Promotion/GetPromotionsApi');
  }
}




export class Common {
  public static getUrl() {
     return 'https://lotymarzen.pl/api/';
    //return 'https://admin-lotymarzen.azurewebsites.net/api/';
     // return 'https://localhost:44358/api/';
//
  }
  public static getUrlWithouApi() {
     return 'https://lotymarzen.pl/';

    //  return 'https://localhost:44358/';

  }

  public static emailValidator(email: string): boolean {
    // tslint:disable-next-line:max-line-length
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === null || email === '') {
      return false;
    }
    if (!EMAIL_REGEXP.test(email)) {
      return true;
    }
    return false;
  }

  public static phoneNumberValidator(number: string): boolean {
    const regExp = /^-?[0-9]+(\.[0-9]*){0,1}$/;
    if (number === null || number === '') {
      return false;
    }
    if (number.length !== 9 || !regExp.test(number)) {
      return true;
    }

    return false;
  }

}

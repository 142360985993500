import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Common } from '../common';
import { CheckReedem } from './reedem';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-reedem',
  templateUrl: './reedem.component.html',
  styleUrls: ['./reedem.component.css']
})
export class ReedemComponent {

  constructor(private http: HttpClient) { }

  ShowPromo = false;
  reedem: CheckReedem[] = [];
  status: string;
  private url: string = Common.getUrl();

  checkReedem(value: string) {
      this.getReedem(value).subscribe(res => {
        if (res.length !== 0) {
        this.ShowPromo = true;
        this.reedem = res;
        console.log(this.reedem)
        } else {
          this.status = 'wrong';
        }
      });
    
  }
  getReedem(key: string): Observable<CheckReedem[]> {
    return this.http.get<CheckReedem[]>(this.url + 'reedemcode/checkreedem?key=' + key);
  }
}
